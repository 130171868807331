// src/components/Dip.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import DipList from "./DipList";
import AddDipForm from "./AddDipForm";
import Header from "./Header";

const Dip = () => {
  const { logout } = useAuth();
  const [dips, setDips] = useState([]);
  const [dip, setDip] = useState({
    token: localStorage.getItem("authToken"),
    dip: "",
    dip_calculator: "",
  });

  const navigate = useNavigate(); // Call useNavigate to get navigate function

  const [credentials] = useState({
    token: localStorage.getItem("authToken"),
  });

  // Fetch Dips from the API
  const fetchDips = async () => {
    try {
      const response = await axios.post(
        "https://drc.freewebhostmost.com/admin/api/getdip",
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        //console.log(response.data.data);
        setDips(response.data.data);
      } else {
      }
    } catch (err) {
      logout();
      //setError(response.data.message);
      navigate("/"); // Use navigate to redirect
    }
  };

  useEffect(() => {
    fetchDips();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <Header />
      <div className="dip-calculator">
        <div className="container">
          <AddDipForm fetchDips={fetchDips} dip={dip} setDip={setDip} />
          <DipList
            dips={dips}
            fetchDips={fetchDips}
            dip={dip}
            setDip={setDip}
          />
        </div>
      </div>
      <Header />
    </>
  );
};
export default Dip;
