// AddCalculatorForm.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const AddCalculatorForm = ({ fetchCalculators, calculator, setCalculator }) => {
  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCalculator({ ...calculator, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(
        "https://drc.freewebhostmost.com/admin/api/calculatorsave",
        calculator,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //console.log('Calculator added:', response.data);
      setSuccess("Item added successfully!");
      fetchCalculators();
      setLoading(false);

      // Clear form after successful submission
      setCalculator({
        count_500: "",
        count_200: "",
        count_100: "",
        count_50: "",
        count_20: "",
        count_10: "",
        count_5: "",
        count_2: "",
        count_1: "",
      });
    } catch (error) {
      //setError('Failed to add Calculator: ' + (error.response?.data?.message || error.message));
      //setLoading(false);
      logout();
      navigate("/"); // Use navigate to redirect
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="product-form">
        <div className="row">
          <div className="col-md-3">
            <input
              type="number"
              name="count_500"
              className="form-control"
              placeholder="500"
              value={calculator.count_500}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_200"
              className="form-control"
              placeholder="200"
              value={calculator.count_200}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_100"
              className="form-control"
              placeholder="100"
              value={calculator.count_100}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_50"
              className="form-control"
              placeholder="50"
              value={calculator.count_50}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_20"
              className="form-control"
              placeholder="20"
              value={calculator.count_20}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_10"
              className="form-control"
              placeholder="10"
              value={calculator.count_10}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_5"
              className="form-control"
              placeholder="5"
              value={calculator.count_5}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_2"
              className="form-control"
              placeholder="2"
              value={calculator.count_2}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3">
            <input
              type="number"
              name="count_1"
              className="form-control"
              placeholder="1"
              value={calculator.count_1}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary bt-form"
              type="submit"
              disabled={loading}
            >
              {loading ? "Adding..." : "Add Calculator"}
            </button>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {success && <p style={{ color: "green" }}>{success}</p>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCalculatorForm;
