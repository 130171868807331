// src/components/CalculatorList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CalculatorList = ({calculators,fetchCalculators,calculator,setCalculator}) => {  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleDelete = async (id) => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      if (window.confirm("Are you sure to delete this Reading?")) {
        //console.log('id',id);
        const staticData = {
          token : localStorage.getItem('authToken'),
          id: id,
        };
        const response = await axios.post('http://127.0.0.1:8000/api/calculatorremove', staticData, {
          headers: {
            'Content-Type': 'application/json',
          },
        }); 
        setSuccess('Item remove successfully!');
        fetchCalculators();       
      }
    } catch (err) {
     // console.log(err);
    }
  };

  return (  
    <div className="show-detils">
        <table className="table table-dark table-striped">
        <thead>
            <tr>
              <th>Actions</th>
              <th>Time</th>
              <th>500</th>
              <th>200</th>
              <th>100</th>
              <th>50</th>
              <th>20</th>
              <th>10</th>
              <th>5</th>
              <th>2</th>
              <th>1</th>
            </tr>
        </thead>
        <tbody>
            {calculators.map((entry,i) => (
            <tr key={i}>
                <td>

                <button
                  onClick={() => handleDelete(entry.id)}
                  className="btn btn-danger ms-2"
                >
                </button>
                </td>
                <td>{entry.created_at}</td>
                <td>{entry.count_500}</td>
                <td>{entry.count_200}</td>
                <td>{entry.count_100}</td>
                <td>{entry.count_50}</td>
                <td>{entry.count_20}</td>
                <td>{entry.count_10}</td>
                <td>{entry.count_5}</td>
                <td>{entry.count_2}</td>
                <td>{entry.count_1}</td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
  );
};

export default CalculatorList;