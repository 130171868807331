// src/components/DensityList.js
import React, { useEffect, useState } from "react";
import axios from "axios";

const DensityList = ({ densitys, fetchDensitys, density, setDensity }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleDelete = async (id) => {
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      if (window.confirm("Are you sure to delete this Reading?")) {
        //console.log('id',id);
        const staticData = {
          token: localStorage.getItem("authToken"),
          id: id,
        };
        const response = await axios.post(
          "https://drc.freewebhostmost.com/admin/api/densityremove",
          staticData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setSuccess("Item remove successfully!");
        fetchDensitys();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div className="show-detils">
      <table className="table table-dark table-striped">
        <thead>
          <tr>
            <th>Actions</th>
            <th>Time</th>
            <th>Density</th>
            <th>Temperature</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {densitys.map((entry, i) => (
            <tr key={i}>
              <td>
                <button
                  onClick={() => handleDelete(entry.id)}
                  className="btn btn-danger ms-2"
                ></button>
              </td>
              <td>{entry.created_at}</td>
              <td>{entry.density}</td>
              <td>{entry.temperature}</td>
              <td>{entry.density_result}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DensityList;
