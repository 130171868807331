// src/components/Calculator.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import CalculatorList from "./CalculatorList";
import AddCalculatorForm from "./AddCalculatorForm";
import Header from "./Header";

const Calculator = () => {
  const { logout } = useAuth();
  const [calculators, setCalculators] = useState([]);
  const [calculator, setCalculator] = useState({
    token: localStorage.getItem("authToken"),
    count_500: "",
    count_200: "",
    count_100: "",
    count_50: "",
    count_20: "",
    count_10: "",
    count_5: "",
    count_2: "",
    count_1: "",
  });

  const navigate = useNavigate(); // Call useNavigate to get navigate function

  const [credentials] = useState({
    token: localStorage.getItem("authToken"),
  });

  // Fetch Calculators from the API
  const fetchCalculators = async () => {
    try {
      const response = await axios.post(
        "https://drc.freewebhostmost.com/admin/api/getcalculator",
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        //console.log(response.data.data);
        setCalculators(response.data.data);
      } else {
      }
    } catch (err) {
      logout();
      //setError(response.data.message);
      navigate("/"); // Use navigate to redirect
    }
  };

  useEffect(() => {
    fetchCalculators();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <Header />

      <div className="du-detils">
        <div className="container">
          <AddCalculatorForm
            fetchCalculators={fetchCalculators}
            calculator={calculator}
            setCalculator={setCalculator}
          />
          <CalculatorList
            calculators={calculators}
            fetchCalculators={fetchCalculators}
            calculator={calculator}
            setCalculator={setCalculator}
          />
        </div>
      </div>
      <Header />
    </>
  );
};
export default Calculator;
