// src/components/ProductList.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Logout = () => {
  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const { logout } = useAuth();
  logout();
  navigate("/"); // Use navigate to redirect
};

export default Logout;
